import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import sql from "../common/sql";
import { FiseHotelEntity } from "../../../backend/src/shared/entity";
import Form from "./Form";
import Fa from "./FontAwesome";

const DetaliiHotel = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [model, setModel] = useState<FiseHotelEntity>({} as FiseHotelEntity);
  const [sezon, setSezon] = useState("");
  const [nrBucJante, setNrBucJante] = useState("");
  const [randuri, setRanduri] = useState("");
  const [raft, setRaft] = useState("");
  const [observatiiCheckout, setObservatiiCheckout] = useState("");
  const [error, setError] = useState<string | null>(null);

  const { isLoading, data, error: queryError } = useQuery<FiseHotelEntity>({
    queryKey: ["FiseHotel", id],
    queryFn: () =>
      sql.getEntity("dbo.FiseHotel", +(id || 0)) as Promise<FiseHotelEntity>,
  });

  useEffect(() => {
    if (data && !isLoading) {
      setModel(data);
      setSezon(data.Sezon || "");
      setNrBucJante(data.NrBucJante !== null ? String(data.NrBucJante) : ""); // Set initial value
      setObservatiiCheckout(data.Observatii_Checkout || ""); // Set initial value
      if (data.Pozitie_hotel) {
        const [initialRanduri, initialRaft] = data.Pozitie_hotel.split("-");
        setRanduri(initialRanduri || "");
        setRaft(initialRaft || "");
      }
    }
    if (queryError) {
      setError("Failed to fetch data");
    }
  }, [data, isLoading, queryError]);

  const handleWinterClick = () => {
    setSezon("Iarna");
  };

  const handleSummerClick = () => {
    setSezon("Vara");
  };

  const handleSave = async () => {
    setError(null);
    if (model.NrBuc === 1) {
      alert("Va rugam introduceti un numar de bucati cauciucuri mai mare decat 1");
      return;
    }
    const updatedModel = {
      ...model,
      Sezon: sezon,
      NrBucJante: nrBucJante,
      Pozitie_hotel: `${randuri}-${raft}`,
    };

    if (updatedModel.Data_depozitare == null) {
      updatedModel.Data_depozitare = new Date().toISOString() as any;
    } else {
      updatedModel.DataModificare = new Date().toISOString() as any;
    }

    updatedModel.UserUltimaModificare =
      sessionStorage.getItem("UserName") || "";
    const payload = {
      ...updatedModel,
      Data_depozitare: updatedModel.Data_depozitare,
      DataModificare: updatedModel.DataModificare
        ? updatedModel.DataModificare
        : null,
    };

    try {
      await sql.saveEntity("dbo.FiseHotel", payload);
      window.scrollTo(0, 0);
      navigate("/search");
    } catch (saveError) {
      setError("Failed to save data");
    }
  };

  const handleCheckOut = async () => {
    setError(null);
    const updatedModel = {
      ...model,
      CheckOutHotel: new Date().toISOString() as any,
      Observatii_Checkout: observatiiCheckout,
    };

    try {
      await sql.saveEntity("dbo.FiseHotel", updatedModel);
      window.scrollTo(0, 0);
      navigate("/");
    } catch (saveError) {
      setError("Failed to update CheckOutHotel");
    }
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setModel({ ...model, [name]: value });
  };

  const handleNrBucJanteChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNrBucJante(event.target.value);
  };

  const handleObservatiiCheckoutChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setObservatiiCheckout(event.target.value);
  };

  const formatDate = (date: Date | null | undefined): string => {
    return date
      ? new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(new Date(date))
      : "";
  };

  return (
    <div className="container mt-4">
      <div className="mb-4">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Form onSubmit={handleSave}>
            <h1 className="mb-3">Detalii Hotel</h1>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="form-group mb-3">
              <label htmlFor="NrAuto">Număr Auto</label>
              <input
                type="text"
                className="form-control"
                id="NrAuto"
                name="NrAuto"
                value={model.NrAuto || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="Nume">Nume</label>
              <input
                type="text"
                className="form-control"
                id="Nume"
                name="Nume"
                value={model.Nume || ""}
                onChange={handleChange}                
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="Telefon">Telefon</label>
              <input
                type="text"
                className="form-control"
                id="Telefon"
                name="Telefon"
                value={model.Telefon || ""}
                onChange={handleChange}                
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="Descriere">Descriere</label>
              <textarea
                className="form-control"
                id="Descriere"
                name="Descriere"
                rows={3}
                value={model.Descriere || ""}
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="Observatii">Observații CheckIn</label>
              <textarea
                className="form-control"
                id="Observatii"
                name="Observatii"
                rows={3}
                value={model.Observatii || ""}
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="Nr bucati cauciucuri">
                <Fa regular="fa-solid fa-tire" /> Nr bucati cauciucuri
              </label>
              <input
                type="text"
                className="form-control"
                id="NrBuc"
                name="NrBuc"
                value={model.NrBuc || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="Jante">
                <Fa regular="fa-solid fa-tire-rugged" /> Jante
              </label>
              <select
                className="form-control"
                id="Jante"
                name="Jante"
                value={model.Jante || ""}
                onChange={handleChange}
                required
              >
                <option value="">Selectează o opțiune</option>
                <option value="Da">Da</option>
                <option value="Nu">Nu</option>
              </select>
            </div>
            {model.Jante === "Da" && (
              <div className="form-group mb-3">
                <label htmlFor="NrBucJante">Nr Bucati Jante</label>
                <input
                  type="text"
                  className="form-control"
                  id="NrBucJante"
                  name="NrBucJante"
                  value={nrBucJante}
                  onChange={handleNrBucJanteChange}
                  required
                />
              </div>
            )}
            <div className="form-group mb-3">
              <label htmlFor="Prezoane">
                <Fa regular="fa-solid fa-gears" /> Prezoane
              </label>
              <select
                className="form-control"
                id="Prezoane"
                name="Prezoane"
                value={model.Prezoane || ""}
                onChange={handleChange}
                required
              >
                <option value="">Selectează o opțiune</option>
                <option value="Da">Da</option>
                <option value="Nu">Nu</option>
              </select>
            </div>

            <div className="form-group mb-3">
              <div className="input-group">
                <span className="me-5">Sezon:</span>
                <span className="input-group-text me-5">
  <span onClick={handleWinterClick}>
    <Fa
      regular={`fa-solid fa-snowflake ${sezon === "Iarna" ? "text-primary" : ""}`}
    />
  </span>
</span>
<span className="input-group-text">
  <span onClick={handleSummerClick}>
    <Fa
      regular={`fa-solid fa-sun ${sezon === "Vara" ? "text-warning" : ""}`}
    />
  </span>
</span>

              </div>
              <input
                type="text"
                className="form-control mt-2"
                id="Sezon"
                name="Sezon"
                value={sezon}
                onChange={(e) => setSezon(e.target.value)}
                readOnly
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="Randuri">Randuri</label>
              <select
                className="form-control"
                id="Randuri"
                name="Randuri"
                value={randuri}
                onChange={(e) => setRanduri(e.target.value)}
                required
              >
                <option value="">Selectează un rand</option>
                {Array.from({ length: 40 }, (_, i) => i + 1).map(i => (
                  <option key={i} value={i}>{i}</option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="Raft">Raft</label>
              <select
                className="form-control"
                id="Raft"
                name="Raft"
                value={raft}
                onChange={(e) => setRaft(e.target.value)}
                required
              >
                <option value="">Selectează un raft</option>
                {['a', 'b', 'c', 'd'].map(letter => (
                  <option key={letter} value={letter}>{letter}</option>
                ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="DataAdaugare">
                Data Adăugării in programul de service
              </label>
              <div className="form-text white-background">
                {formatDate(model.DataAdaugare)}
              </div>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="UserAdaugare">
                Nume angajat care a adaugat operatia in programul de service
              </label>
              <div className="form-text white-background">                
                {model.AngajatFinalizatOperatiePrgService}
              </div>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="Data_depozitare">Data Depozitării</label>
              {model.Data_depozitare !== null ? (
                <div className="form-text white-background">
                  {formatDate(model.Data_depozitare)}
                </div>
              ) : (
                <div className="form-text white-background">NU EXISTA</div>
              )}
            </div>
            <div className="form-group mb-3">
              <label htmlFor="DataModificare">Data ultimei modificării in fisa</label>
              <div className="form-text white-background">
                {formatDate(model.DataModificare)}
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="UserUltimaModificare">
                Utilizatorul ce a facut ultima modificare
              </label>
              <div className="form-text white-background">
                {model.UserUltimaModificare}
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="CheckOutHotel">Data Checkout</label>
              <div className="form-text white-background">
                {formatDate(model.CheckOutHotel)}
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="ObservatiiCheckout">Observații Checkout</label>
              <textarea
                className="form-control"
                id="ObservatiiCheckout"
                name="ObservatiiCheckout"
                rows={3}
                value={observatiiCheckout}
                onChange={handleObservatiiCheckoutChange}
              />
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary me-2"
                onClick={() => navigate("/search")}
              >
                Înapoi
              </button>
              <button type="submit" className="btn btn-primary me-2">
                Salvează
              </button>
              <button
                type="button"
                className="btn btn-warning"
                onClick={handleCheckOut}
              >
                CheckOut Hotel
              </button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default DetaliiHotel;
