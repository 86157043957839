import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import sql from "../common/sql";
import { AngajatiEntity } from "../../../backend/src/shared/entity";

const Keypad: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data: model, isLoading } = useQuery<AngajatiEntity[]>({
    queryKey: ["AngajatiListQuery"],
    queryFn: () => sql.AngajatiListQuery(),
  });

  const validatePin = useCallback(() => {
    if (!model || isLoading) return;

    const user = model.find((item) => item.Pin === Number(inputValue));
    if (user) {
      console.log("Pin is correct!");
      sessionStorage.setItem("UserName", user.Nume);
      navigate("/search");
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setInputValue("");
        setShowAlert(false);
      }, 1500);
    }
  }, [inputValue, model, isLoading, navigate]);

  useEffect(() => {
    if (inputValue.length === 4) {
      validatePin();
    }
  }, [inputValue, validatePin]);

  const handleNumberClick = (number: string) => {
    if (inputValue.length < 4) {
      setInputValue(inputValue + number);
    }
    setShowAlert(false);
  };

  const handleBackspaceClick = () => {
    setInputValue(inputValue.slice(0, -1));
  };

  const handleOkClick = () => {
    if (inputValue.length === 4) {
      validatePin();
    } else {
      console.log("Please enter a 4-digit PIN");
    }
  };

  return (
    <div className="Keypad">
      <h1>ENTER PIN CODE</h1>
      <input
        type="text"
        className="form-control form-control-lg text-center mb-4"
        value={
          inputValue.length > 1
            ? inputValue.slice(0, -1).replace(/./g, "*") + inputValue.slice(-1)
            : inputValue
        }
        readOnly
      />
      {showAlert && <div className="alert alert-danger">Incorrect PIN</div>}
      <div className="btn-group-vertical w-100">
        <div className="btn-group w-100">
          {["1", "2", "3"].map((number) => (
            <button
              key={number}
              type="button"
              className="btn btn-outline-secondary w-100"
              onClick={() => handleNumberClick(number)}
            >
              {number}
            </button>
          ))}
        </div>

        <div className="btn-group w-100">
          {["4", "5", "6"].map((number) => (
            <button
              key={number}
              type="button"
              className="btn btn-outline-secondary w-100"
              onClick={() => handleNumberClick(number)}
            >
              {number}
            </button>
          ))}
        </div>
        <div className="btn-group w-100">
          {["7", "8", "9"].map((number) => (
            <button
              key={number}
              type="button"
              className="btn btn-outline-secondary w-100"
              onClick={() => handleNumberClick(number)}
            >
              {number}
            </button>
          ))}
        </div>
        <div className="btn-group w-100">
          <button
            type="button"
            className="btn btn-outline-secondary w-100"
            onClick={handleBackspaceClick}
          >
            &lt;&lt;
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary w-100"
            onClick={() => handleNumberClick("0")}
          >
            0
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary w-100"
            onClick={handleOkClick}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default Keypad;
