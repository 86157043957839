import React from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Navbar from './Navbar';
import Footer from './Footer';
import Homepage from './Homepage';
import SearchHotel from './SearchHotel';
import DetaliiHotel from './DetaliiHotel';
import RaportOperatiiFinalizatePerioadaCuTotalLuna from './RaportOperatiiFinalizatePerioadaCuTotalLuna';

const Layout: React.FC = () => {
    const location = useLocation(); // Get current location within the Router context
    const isRoot = location.pathname === '/'; // Check if the current path is root

    return (
        <>
            {!isRoot && <Navbar />}
            <main className='container pt-4 pb-5'>
                <TransitionGroup>
                    <CSSTransition key={location.key} classNames="fade" timeout={1000}>
                        <Routes location={location}>
                            <Route path="/" element={<Homepage />} />
                            <Route path="/search" element={<SearchHotel />} />
                            <Route path="/search/:id" element={<DetaliiHotel />} />
                            <Route path="/raport" element={<RaportOperatiiFinalizatePerioadaCuTotalLuna />} />
                        </Routes>
                    </CSSTransition>
                </TransitionGroup>
            </main>
            {!isRoot && <Footer />}
        </>
    );
};

const AppRouter: React.FC = () => {
    return (
        <Router>
            <Layout />
        </Router>
    );
};

export default AppRouter;
