import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import date picker styles
import { useQuery } from "@tanstack/react-query"; // Import react-query
import sql from "../common/sql"; // Ensure this import path is correct

// Register the components from Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Define the MonthlyTotalData type to match the expected structure
type MonthlyTotalData = {
  OperationYear: number;
  OperationMonth: number;
  TotalValue: number;
};

// Define the AngajatiEntity type (based on the database schema)
type AngajatiEntity = {
  Id: number;
  Nume: string;
};

// Array of Romanian month names
const monthNamesRomanian = [
  "Ianuarie",
  "Februarie",
  "Martie",
  "Aprilie",
  "Mai",
  "Iunie",
  "Iulie",
  "August",
  "Septembrie",
  "Octombrie",
  "Noiembrie",
  "Decembrie",
];

const EmployeeEarnings: React.FC = () => {
  const [data, setData] = useState<Record<number, MonthlyTotalData[]>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedEmployees, setSelectedEmployees] = useState<
    Record<number, boolean>
  >({}); // Track which employees are selected

  // State for date pickers
  const [startDate, setStartDate] = useState<Date | null>(
    new Date("2024-01-01")
  ); // Default start date
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  ); // Default end date to the last day of the current month

  // Fetch the list of employees using react-query
  const { data: model, isLoading: isEmployeesLoading } = useQuery<
    AngajatiEntity[]
  >({
    queryKey: ["AngajatiListQuery"],
    queryFn: () => sql.AngajatiListQuery(),
  });

  useEffect(() => {
    // Fetch data for each employee if we have the employee list
    const fetchData = async () => {
      if (!startDate || !endDate || !model) {
        return; // Ensure dates and model are set before fetching
      }

      setLoading(true);
      setError(null);

      try {
        const results: Record<number, MonthlyTotalData[]> = {};

        // Fetch data for each employee
        await Promise.all(
          model.map(async (employee) => {
            const result = await sql.GetOperatiiFinalizatePerioadaCuTotalLunar({
              idAngajat: employee.Id,
              dataStart: startDate.toISOString(),
              dataEnd: endDate.toISOString(),
            });

            // Check if the result is an array and assign it to the employee's ID key
            if (Array.isArray(result)) {
              results[employee.Id] = result;
            }
          })
        );

        setData(results); // Set the state to the fetched data

        // Initialize selectedEmployees state with all values set to false
        if (Object.keys(selectedEmployees).length === 0) {
          const initialSelectedState: Record<number, boolean> = {};
          model.forEach((employee) => {
            initialSelectedState[employee.Id] = false; // Unchecked by default
          });
          setSelectedEmployees(initialSelectedState);
        }
      } catch (error) {
        setError("An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, model, selectedEmployees]);

  // Handle checkbox change
  const handleCheckboxChange = (employeeId: number) => {
    setSelectedEmployees((prevState) => ({
      ...prevState,
      [employeeId]: !prevState[employeeId],
    }));
  };

  // Filter data for the chart to match the selected date range
  const filteredDates: { year: number; month: number }[] = []; // Explicitly typing the array

  let current = new Date(startDate!.getFullYear(), startDate!.getMonth(), 1);
  while (current <= endDate!) {
    filteredDates.push({
      year: current.getFullYear(),
      month: current.getMonth() + 1,
    });
    current.setMonth(current.getMonth() + 1);
  }

  const filteredMonthNames = filteredDates.map(
    (date) => `${monthNamesRomanian[date.month - 1]} ${date.year}`
  );

  // Prepare data for the chart
  const chartData = {
    labels: filteredMonthNames, // X-axis labels for each month name in Romanian within the selected range
    datasets: model
      ? model
          .filter((employee) => selectedEmployees[employee.Id]) // Filter based on selected employees
          .map((employee) => ({
            label: employee.Nume, // Employee name as label for the line
            data: filteredDates.map((date) => {
              const employeeData = data[employee.Id] || [];
              const monthData = employeeData.find(
                (entry) =>
                  entry.OperationYear === date.year &&
                  entry.OperationMonth === date.month
              );
              return monthData ? monthData.TotalValue : 0; // Y-axis data for each month
            }),
            fill: false,
            backgroundColor: `rgba(${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, 0.2)`,
            borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)}, 1)`,
          }))
      : [],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Valoarea totală a operațiunilor pe lună per angajat",
      },
    },
  };

  // Render the component
  return (
    <div>
      <h2>Performanta Angajati</h2>
      <div style={{ marginBottom: "20px" }}>
        <label>
          Data de început:
          <DatePicker
            selected={startDate}
            onChange={(date: Date | null) => setStartDate(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </label>
        <label style={{ marginLeft: "10px" }}>
          Data de sfârșit:
          <DatePicker
            selected={endDate}
            onChange={(date: Date | null) => setEndDate(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </label>
      </div>
      {loading || isEmployeesLoading ? <p>Se încarcă...</p> : null}
      {error && <p>{error}</p>}
      {!loading &&
      !isEmployeesLoading &&
      !error &&
      Object.keys(data).length > 0 ? (
        <>
          <Line data={chartData} options={chartOptions} />
          <div style={{ marginTop: "20px" }}>
            <table>
              <tbody>
                {model &&
                  model.length > 0 &&
                  Array.from({ length: Math.ceil(model.length / 7) }).map(
                    (_, rowIndex) => (
                      <tr key={rowIndex}>
                        {model
                          .slice(rowIndex * 7, rowIndex * 7 + 7)
                          .map((employee) => (
                            <td key={employee.Id} style={{ padding: "5px" }}>
                              <input
                                type="checkbox"
                                checked={selectedEmployees[employee.Id]}
                                onChange={() =>
                                  handleCheckboxChange(employee.Id)
                                }
                              />
                              <label style={{ marginLeft: "5px" }}>
                                {employee.Nume}
                              </label>
                            </td>
                          ))}
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        !loading &&
        !isEmployeesLoading && (
          <p>Nicio dată disponibilă pentru perioada selectată.</p>
        )
      )}
    </div>
  );
};

export default EmployeeEarnings;
