import { Link } from "react-router-dom";
import Fa from "./FontAwesome";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand navbar-light bg-light">
      <div className="container-fluid d-flex justify-content-center">
        <div className="d-flex justify-content-between w-100">
          {/* Left aligned home icon */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/search" className="nav-link">
                <Fa regular="home" /> {/* Adjust the icon class name if necessary */}
              </Link>
            </li>
          </ul>
          {/* Centered logo */}
          <img src="/Yokohama-logo-mic.png" alt="Yokohama Logo" style={{ maxWidth: "300px" }} />
          {/* Right aligned sign-out icon */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                <Fa regular="sign-out-alt" /> {/* Ensure you are using the correct class name for your icon */}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
