import React from 'react';
import Keypad from './Keypad';
import LoadingScreen from './LoadingScreen';
import useScreenSize from '../hooks/useScreenSize';


const Homepage: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { width, height } = useScreenSize();

  return (
    <div className="App">
      {/* <LoadingScreen isLoading={isLoading} /> */}
      <header className={`App-header `}>
        <img
          src="/Yokohama-logo.png"
          alt="Yokohama Logo"
          className="Yokohama-logo"
        />
        <div className="unfold-container">
          <Keypad />
        </div>
        {/* <p>Screen Width: {width}px</p>
        <p>Screen Height: {height}px</p> */}
      </header>
    </div>
  );
};

export default Homepage;
