// import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <nav
        className="navbar bg-dark border-bottom border-body py-4"
        data-bs-theme="dark"
      >
        <div className="container">
          <div className="d-flex align-items-center">
            <p className="text-centered text-muted text-justify m-0 p-0 ms-md-5">
              Yokohama 2024. All rights reserved.
            </p>
          </div>
        </div>
      </nav>
      <nav className="partners-area">
        <div className="container d-flex justify-content-between"></div>
      </nav>
    </footer>
  );
};

export default Footer;
