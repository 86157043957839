import React, { createContext } from 'react'

export const FormContext = createContext<boolean>(true);

const Form = ({ children = null as any, onSubmit = () => { } }) => {
    const [valid, setValid] = React.useState(true);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit();
    }
    const handleInvalid = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (valid) {
            setValid(false);
        }
        (form.querySelector(`:invalid`) as HTMLElement)?.scrollIntoView({ behavior: "smooth", block: "center" });
        (form.querySelector(':invalid') as HTMLElement)?.focus();
    }

    return (
        <FormContext.Provider value={valid}>
            <form onSubmit={handleSubmit} onInvalid={handleInvalid}>
                {children}
            </form>
        </FormContext.Provider>
    )
}

export default Form
